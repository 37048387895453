<template>
  <v-card elevation="0" class="chart-card">
    <v-card-title class="title-card-chart">Total Realizado</v-card-title>
    <v-card-text>
      <Chart :chartOptions="chartOptions" />
    </v-card-text>
  </v-card>
</template>

<script>
// import Chart from "@/components/shared/BaseChartTeste";
import sistema from "@/services/http/sistemaService";
import comissao from "@/services/http/comissaoService";

export default {
  props: {
    categories: {},
    series: {},
    colaborador: {
      type: String
    }
  },
  components: {
    // Chart
    Chart: () => import("@/components/shared/BaseChartTeste")
  },
  data() {
    return {
      enterprise: 0,
      years: {},
      dataDashboard: { name: "Resultado", data: [] },
      chartOptions: {
        chart: {
          type: "spline",
          // type: "areaspline",
          backgroundColor: "rgba(0,0,0,0)",
          height: 300
        },
        // linear-gradient(90deg, rgba(10,32,58,1) 0%, rgba(42,87,135,1) 100%)
        colors: ["#D46D26", "#e25ced"],
        title: {
          text: ""
        },
        legend: {
          itemStyle: {
            color: "rgba(255,255,255, 0.3)"
          }
        },
        xAxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
          ],
          labels: {
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          }
        },
        yAxis: {
          title: {
            text: "Nº de Negócios",
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          },
          gridLineColor: "rgba(255,255,255, 0.1)",
          labels: {
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          }
        },
        plotOptions: {
          // area: {
          //   fillColor: {
          //     linearGradient: [0, 0, 0, 300],
          //     stops: [
          //       [0, "rgba(255,255,255, 0.5)"],
          //       [1, "rgba(0,0,0,0)"]
          //     ]
          //   }
          // },
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: false
          }
          // series: {
          //   fillColor: {
          //     linearGradient: [0, 0, 0, 300],
          //     stops: [
          //       [0, "rgba(255,255,255, 0.5)"],
          //       [1, "rgba(0,0,0,0)"]
          //     ]
          //   }
          // },
          // areaspline: {
          //   fillOpacity: 0.5
          // }
        },
        credits: {
          enabled: false
        },
        series: []
      }
    };
  },

  methods: {
    //TO-DO: GRAFICO DINAMICO POR EMPRESA
    //MELHORAR FILTRO DE APURAÇÃO QUE RESETA APOS VOLTAR AO COMPONENT
    async fetchResMonth() {
      this.chartOptions.series = [];
      for (let years of this.years) {
        if (years.ano_ref != 2020) {
          let month = [];
          let res = [];
          let { data } = await comissao()
            .metaEquipe()
            .show({
              per_page: -1,
              ano_ref: years.ano_ref,
              id_tipo_evento: "C",
              id_tipo_dado: "R"
            });
          for (let j = 1; j <= 12; j++) {
            month.push(data.data.filter(item => item.mes_ref == j.toString()));
          }

          month.map((item, k) => {
            let counter = 0;
            item.map(subitem => {
              if (
                subitem.id_indicador == "11110001" ||
                subitem.id_indicador == "11110002" ||
                subitem.id_indicador == "11110006"
              ) {
                counter += parseFloat(subitem.qtde) || 0;
              }
            });
            res[k] = counter;
          });

          this.chartOptions.series.push({
            name: years.ano_ref,
            data: res
          });
        }
      }
    },
    async fetchYears() {
      let { data } = await sistema()
        .anos()
        .show();
      this.years = data.data;
    }
  },

  watch: {
    async colaborador() {
      this.fetchResMonth();
    }
  },

  async mounted() {
    await this.fetchYears();
    await this.fetchResMonth();
  }
};
</script>

<style>
.chart-card {
  border-radius: 0px !important;
  background: #141e30;
  background: linear-gradient(90deg, #0c2646 0, #204065 60%, #2a5788);
  padding-bottom: 40px;
}

.title-card-chart {
  color: white;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 20px;
}
</style>
